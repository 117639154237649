@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$app-primary: mat-palette($mat-green, 900);
$app-accent: mat-palette($mat-green, 900);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

@fontfamily: Roboto, "Helvetica Neue", sans-serif;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pointer-cursor {
  cursor: pointer;
}

.primary-background {
  background-color: mat-color($app-primary, default);
}

.error-notification-overlay {
  color: mat-color(mat-palette($mat-red, 600), default-contrast);
  background-color: mat-color(mat-palette($mat-red, 600));
}

.info-notification-overlay {
  color: mat-color(mat-palette($mat-blue, 600), default-contrast);
  background-color: mat-color(mat-palette($mat-blue, 600));
}

.success-notification-overlay {
  color: mat-color($app-primary, default-contrast);
  background-color: mat-color($app-primary);
}

.warning-notification-overlay {
  color: mat-color(mat-palette($mat-orange, 600), default-contrast);
  background-color: mat-color(mat-palette($mat-orange, 600));
}

:root {
  --divider-gray: rgba(220, 220, 220, 0.5);
  --button-primary: rgba(63, 81, 181, 1);
  --button-primary-disabled: rgba(140, 205, 242, 1);
  --sidebar-selected: rgba(233 229 229);
}
